<script lang="ts" setup>
// @ts-expect-error JS library
import { useMq } from 'vue3-mq'
import { storeToRefs } from 'pinia'
import { useRadar } from '~/store/radar'
import { useLocaleThemePath } from '~/composables/locale'
import { LordIconSource, LordIconTrigger } from '~/types/assets'
import type { LordIcon } from '~/types/assets'
import type { RadarRoute } from '~/types/custom'

const props = defineProps<{
  position: 'top' | 'left' | 'right' | 'bottom'
}>()

const radar = useRadar()

const { routeTop, routeLeft, routeRight, routeBottom } = storeToRefs(radar)

const { xl } = useMq()

const icon: ComputedRef<LordIcon> = computed(() => {
  return {
    src: {
      top: LordIconSource.chevronUp,
      left: LordIconSource.chevronLeft,
      right: LordIconSource.chevronRight,
      bottom: LordIconSource.chevronDown,
    }[props.position],
    trigger: LordIconTrigger?.loop,
    revert: true,
    delay: 500,
    size: 50,
  }
})

const transition: ComputedRef<string> = computed(() => `fade-${props.position}`)

const targetRoute: ComputedRef<RadarRoute | null> = computed(
  () =>
    ({
      top: routeTop.value,
      left: routeLeft.value,
      right: routeRight.value,
      bottom: routeBottom.value,
    })[props.position],
)

const classes: ComputedRef<string> = computed(
  () =>
    ({
      top: 'top-1 left-[50%] translate-x-[-50%] bg-gradient-to-t border-b-2 dark:border-b-4',
      left: 'top-[50%] left-1 translate-y-[-50%] bg-gradient-to-l border-r-2 dark:border-r-4',
      right: 'top-[50%] right-1 translate-y-[-50%] bg-gradient-to-r border-l-2 dark:border-l-4',
      bottom: 'bottom-3 left-[50%] translate-x-[-50%] bg-gradient-to-b border-t-2 dark:border-t-4',
    })[props.position],
)

const styles: ComputedRef<string> = computed(
  () =>
    ({
      top: 'clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%)',
      left: 'clip-path: polygon(0 0, 100% 10%, 100% 90%, 0 100%)',
      right: 'clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%)',
      bottom: 'clip-path: polygon(10% 0, 90% 0, 100% 100%, 0 100%)',
    })[props.position],
)
</script>

<template lang="pug">
Transition(:name="transition")
  .controller(
    v-if="targetRoute !== null"
    v-tooltip="$t(\
      `layout.controllers.${position}.tooltip`,\
      { name: $t(`routes.${targetRoute.name}.name`) }\
    )"
    :class="classes"
    :style="styles"
    data-test="controller"
    @click="radar.onNavigation(targetRoute?.index)"
  )
    NuxtLink(
      :to="useLocaleThemePath(targetRoute?.path)"
      :title="$t(\
        `layout.controllers.${position}.anchor.title`,\
        { route: $t(`routes.${targetRoute?.name}.name`) }\
      )"
      :aria-label="$t(\
        `layout.controllers.${position}.anchor.aria-label`,\
        { route: $t(`routes.${targetRoute?.name}.name`) }\
      )"
      data-test="controller-link"
      :class="position === 'top' || position === 'bottom' ? 'px-10 xl:px-16' : 'py-10 xl:py-16'"
    )
      IconLord(v-bind="icon", data-test="controller-icon")
</template>

<style lang="sass" scoped>
.controller
  @apply hidden lg:block fixed text-primary from-light-200 to-light-300 dark:from-dark-900 dark:to-black border-primary hover:border-secondary cursor-pointer transition-all z-20
  a
    @apply w-full h-full flex items-center justify-center
    [class*="i-"]
      @apply bg-primary
    &:hover [class*="i-"]
      @apply bg-secondary
</style>
