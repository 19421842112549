<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import type { TranslateResult } from 'vue-i18n'
import { useLocaleThemePath } from '~/composables/locale'
import { useSearch } from '~/store/search'
import { LordIconSource, LordIconTrigger, MDIIcon } from '~/types/assets'
import type { Flag, LordIcon } from '~/types/assets'
import { CountryFlag } from '~/types/custom'

const search = useSearch()
const { t, locale } = useI18n()

const animating = ref(false)

const { term, pending, resultItems } = storeToRefs(search)

const flag = reactive({
  countryCode: locale.value,
  src: CountryFlag[locale.value as keyof typeof CountryFlag]
} as Flag)

const termModel = computed({
  get: (): string | null => term.value,
  async set(_term: string | null): Promise<void> {
    // wait for block transitions between welcome and matches list
    if (!_term || !term.value) {
      animating.value = true
      setTimeout(() => (animating.value = false), 700)
    }
    await search.setTerm(_term)
  }
})

const suggestions: Array<{
  textContent: TranslateResult
  slug: string
}> = [
  {
    textContent: t('layout.search.welcome.terms.0'),
    slug: 'vue+js'
  },
  {
    textContent: t('layout.search.welcome.terms.1'),
    slug: 'boolean'
  },
  {
    textContent: t('layout.search.welcome.terms.2'),
    slug: 'HTML'
  },
  {
    textContent: t('layout.search.welcome.terms.3'),
    slug: 'CSS'
  },
  {
    textContent: t('layout.search.welcome.terms.4'),
    slug: 'PHP'
  },
  {
    textContent: t('layout.search.welcome.terms.5'),
    slug: 'dukes'
  },
  {
    textContent: t('layout.search.welcome.terms.6'),
    slug: 'comics'
  },
  {
    textContent: t('layout.search.welcome.terms.7'),
    slug: 'seed'
  }
]

const searchIcon: LordIcon = {
  src: LordIconSource?.search,
  trigger: LordIconTrigger?.loop,
  revert: true,
  delay: 500,
  size: 55
}
</script>

<template lang="pug">
.layout__search(
  data-test="search-sidebar"
)
  VForm.v-form(@submit="() => {}")
    .v-form-row
      .v-form-item
        label.v-form-item__label(
          for="form-search-input"
        ) {{ $t('layout.search.input.label') }}
        .v-form-item__field-wrapper
          NuxtLink(
            :to="`${useLocaleThemePath?.(undefined, { search: termModel })}`"
            data-test="search-query"
          )
            UnoIcon(:class="MDIIcon?.open")
          VField.v-form-item__field(
            id="form-search-input"
            ref="searchInput"
            v-model="termModel"
            name="form-search-input"
            type="search"
            data-test="search-input"
            :placeholder="$t('layout.search.input.placeholder')"
          )
  TransitionGroup.layout__search__wrapper(tag="div" name="fade-left")
    .layout__search__loader(
      v-if="pending && !animating"
    )
      IconLord(v-bind="loader")
    .layout__search__matches(
      v-else-if=" \
        resultItems?.length && \
        termModel?.length >= 1 && \
        !animating \
      "
    )
      TransitionGroup(tag="ul" name="fade-left")
        li.text-primary(
          v-for="(match, index) in resultItems"
          :key="`match-item-${index}`"
          data-test="search-match"
        )
          NuxtLink.layout__search__repo-match(
            v-if="match.__typename === 'Repo' && match.repo"
            :to="useLocaleThemePath?.(`/git/${match?.repo?.slug}`)"
          )
            .layout__search__match-wrapper
              UnoIcon(:class="MDIIcon?.bug")
              div
                span {{ $t('layout.search.match.tag.repo') }}
                p.title(v-if="match?.repo?.name") {{ match?.repo?.name }}
                p.description(v-if="match?.repo?.description") {{ match?.repo?.description }}
            NuxtImg(
              v-if="match?.repo?.asset?.nuxtPath || match?.repo?.asset?.thumb"
              :src="match?.repo?.asset?.nuxtPath || match?.repo?.asset?.thumb"
              :provider="match?.repo?.asset?.provider !== 'unknown' ? match?.repo?.asset?.provider : 'vimeo'"
              :alt="$t('layout.search.match.images.default.alt', { type: match.__typename?.toLowerCase(), name: match?.repo?.name })"
              :title="$t('layout.search.match.images.default.title', { type: match.__typename?.toLowerCase(), name: match?.repo?.name })"
              :aria-label="$t('layout.search.match.images.default.aria-label', { type: match.__typename?.toLowerCase(), name: match?.repo?.name })"
              :longdesc="$t('layout.search.match.images.default.longdesc', { type: match.__typename?.toLowerCase(), name: match?.repo?.name, n: index })"
              width="50"
              height="50"
              decoding="async"
              loading="lazy"
            )
          a.layout__search__asset-match(
            v-else-if="match.__typename === 'Asset' && match?.asset"
            target="_blank"
            rel="nofollow"
            :href="match.asset.thumb"
          )
            .layout__search__match-wrapper
              UnoIcon(:class="MDIIcon?.asset")
              div
                span {{ $t('layout.search.match.tag.asset') }}
                p.title {{ match?.asset?.slug }}
            NuxtImg(
              v-if="match?.asset?.nuxtPath || match?.asset?.thumb"
              :src="match?.asset?.nuxtPath || match?.asset?.thumb"
              :provider="match?.asset?.provider !== 'unknown' ? match?.asset?.provider : 'vimeo'"
              :alt="$t('layout.search.match.images.asset.alt', { asset: match?.asset?.slug })"
              :title="$t('layout.search.match.images.asset.title', { asset: match?.asset?.slug })"
              :aria-label="$t('layout.search.match.images.asset.aria-label', { asset: match?.asset?.slug })"
              width="50"
              height="50"
              decoding="async"
              loading="lazy"
            )
          NuxtLink.layout__search__document-match(
            v-else-if="match?.__typename === 'Document' && match?.document"
            :to="useLocaleThemePath?.(`/assets/docs/${match?.document?.slug}`)"
          )
            .layout__search__match-wrapper
              UnoIcon(:class="MDIIcon?.cert")
              div
                span {{ $t('layout.search.match.tag.documentation') }}
                p.title {{ match?.document?.name }}
                p.description {{ match?.document?.title }}
            UnoIcon(:class="MDIIcon?.markdown")
          .layout__search__topic-match(
            v-else-if="match.__typename === 'Topic'"
          )
            .layout__search__match-wrapper
              UnoIcon(:class="MDIIcon?.topic")
              div
                span {{ $t('layout.search.match.tag.topic') }}
                p.title {{ match?.topic?.name }}
          NuxtLink.layout__search__skill-match(
            v-else-if="match.__typename === 'Skill' && match?.skill"
            :to="useLocaleThemePath?.(`/skills/${skill?.name}`)"
          )
            .layout__search__match-wrapper
              UnoIcon(:class="MDIIcon?.skill")
              div
                span {{ $t('layout.search.match.tag.skill') }}
                p.title {{ match?.skill?.name }}
            span {{ match?.skill?.percentage }} / 100
          NuxtLink.layout__search__media-match(
            v-else-if="match.__typename === 'Media'"
            :to="useLocaleThemePath?.(`/media/${match?.media?.slug}`)"
          )
            .layout__search__match-wrapper
              UnoIcon(:class="MDIIcon?.vimeo")
              div
                span {{ $t('layout.search.match.tag.media') }}
                p.title {{ match?.media?.name }}
          NuxtLink.layout__search__route-match(
            v-else-if="match.__typename === 'Route'"
            :to="useLocaleThemePath(match.route.path)"
          )
            .layout__search__match-wrapper
              UnoIcon(:class="MDIIcon?.routes")
              div
                span {{ $t('layout.search.match.tag.route') }}
                p.title {{ match?.route?.name }}
            IconFlag(:flag="flag")
    .layout__search__404(v-else-if="termModel && !animating")
      UnoIcon(:class="MDIIcon?.alert")
      p.text-primary {{ $t('layout.search.404') }}
    .layout__search__suggestions(v-else-if="!animating", data-test="search-landing")
      p {{ $t('layout.search.welcome.text-content') }}
      ul
        li(
          v-for="(suggestion, index) in suggestions"
          :key="`suggestion-${index}`"
        )
          span(
            @click="termModel = suggestion.slug"
          ) {{ suggestion.textContent }}
</template>

<style lang="sass" scoped>
.v-form
  @apply p-2
  .v-form-row
    .v-form-item
      @apply w-full
    .v-form-item__field-wrapper
      @apply w-full flex items-center gap-3 text-dark-400 hover:text-dark-200 transition-all relative
      a
        @apply flex items-center
      .v-form-item__field-wrapper__reset
        @apply absolute top-0 right-0 bottom-0 flex items-center pr-1 cursor-pointer
.layout__search
  @apply w-full max-w-full overflow-hidden bg-dark-950 shadow-2xl border-y border-l border-primary mt-1
  .layout__search__wrapper
    @apply w-full
    .layout__search__matches
      @apply w-full max-h-[80vh] lg:max-h-[65vh] overflow-y-auto overflow-x-hidden scrollbar-secondary pb-64 lg:pb-16
      ul
        @apply w-full
        li
          @apply border-y p-2 border-primary hover:bg-dark-900
          .layout__search__repo-match,
          .layout__search__asset-match,
          .layout__search__document-match,
          .layout__search__topic-match,
          .layout__search__skill-match,
          .layout__search__media-match,
          .layout__search__route-match
            @apply flex justify-between items-center gap-4 no-underline
            .layout__search__match-wrapper
              @apply flex items-center justify-start gap-4
              & > div
                @apply flex flex-col items-start
                span
                  @apply text-primary text-sm underline
                p.title
                  @apply text-secondary text-base font-bold overflow-ellipsis w-[170px] md:w-[220px] overflow-hidden
                p.description
                  @apply text-white text-xs font-thin max-w-[170px] md:w-[220px]
            img
              @apply w-[100px] h-[70px] object-cover object-center
            & > span
              @apply text-primary font-bold
    .layout__search__404
      @apply flex-center gap-2 text-secondary pb-8
      p
        @apply text-primary
    .layout__search__suggestions
      @apply text-primary px-4 pb-8
      p
        @apply text-white text-lg font-bold
      ul
        @apply list-disc list-inside
        li
          span
            @apply underline text-base cursor-pointer
</style>
