<script lang="ts" setup>
// @ts-expect-error JS library
import { useMq } from 'vue3-mq'
import { storeToRefs } from 'pinia'
import { useRadar } from '~/store/radar'
import { useSearch } from '~/store/search'
import type { LordIcon } from '~/types/assets'
import { LordIconSource, LordIconTrigger } from '~/types/assets'

const emits = defineEmits<{ (e: 'onSectionScroll'): void }>()

const search = useSearch()

const { section, route, routeComponents } = storeToRefs(useRadar())

const { status } = storeToRefs(search)

const { xs, sm } = useMq()

const downIcon: LordIcon = {
  src: LordIconSource?.down,
  trigger: LordIconTrigger?.loop,
  revert: true,
  delay: 500,
  size: 65,
}
</script>

<template lang="pug">
div
header
  .header__desktop__radar
    LayoutRadar.z-100
    Transition(name="fade-top")
      .header__desktop__radar__active-section(
        v-if="route?.name && route?.icon?.src && section?.CHAR && section?.char"
        v-tooltip="`Page ${route?.name} - Section ${section?.word}`"
        data-test="active-section"
      )
        UnoIcon.text-xl.bg-dark-900(:class="route?.icon?.src")
        p {{ $t(`routes.${route?.name}.name`) }} |
          span {{ section?.CHAR }}{{ section?.char }}
  .header__desktop__control-panel
    LayoutHeaderControlPanel
    ClientOnly
      Transition.header__desktop__control-panel__wrapper(
        tag="div" name="fade-right" class="w-full flex justify-end items-start"
      )
        .header__desktop__control-panel__scroller(
          v-if=" \
            status === false && \
            !(section?.index >= (routeComponents.length - 1)) \
          "
          data-test="scroll-down"
          @click="emits('onSectionScroll')"
        )
          span {{  $t('layout.scroll-down') }}
          IconLord(v-bind="downIcon")
        .header__desktop__control-panel__search(
          v-else-if="status === true"
          v-click-outside="() => !xs && !sm ? search.setStatus(false) : () => {}"
        )
          LayoutSearch
</template>

<style lang="sass" scoped>
header
  @apply hidden lg:block
  .header__desktop__radar
    @apply fixed w-fit flex items-start top-0 left-0 z-40 p-1
  .header__desktop__radar__active-section
    @apply fixed left-[260px] w-fit border-x-8 border-double border-primary rounded-b-lg bg-[rgba(0,0,0,0.4)] text-primary font-bold py-1.5 text-sm flex-center gap-2 mr-1 xl:ml-12 px-8 xl:px-12 shadow-2xl
    span
      @apply ml-2 text-xl text-secondary
  .header__desktop__control-panel
    @apply fixed top-0 right-0 z-40 p-1 flex flex-col items-end w-[40%] 2xl:w-4/12
    .header__desktop__control-panel__wrapper
      @apply w-full flex justify-end
    .header__desktop__control-panel__scroller
      @apply w-fit flex flex-col items-end p-4 cursor-pointer
      span
        @apply text-lg text-primary font-bold
      &:hover
        span
          @apply underline
      .header__desktop__control-panel__search
        @apply w-full px-2 pt-2 bg-dark-800 border border-primary max-w-[90%]
</style>
