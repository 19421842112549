import { defineStore, storeToRefs } from 'pinia'
import type { AsyncDataExecuteOptions } from 'nuxt/dist/app/composables/asyncData'
import { useRadar } from '~/store/radar'
import { type SearchResponse } from '~~/server/api/search.get'

export const useSearch = defineStore('search', () => {
  const term: Ref<null | string> = ref(null)
  const status: Ref<boolean | null> = ref(false)
  const data: Ref<SearchResponse | 400 | null> = ref(null)
  const pending: Ref<boolean> = ref(false)
  const refresh: Ref<((opts?: AsyncDataExecuteOptions | undefined) => Promise<void>) | null> = ref(null)
  const { locale } = useI18n()

  const resultItems = computed(() => (typeof data?.value !== 'number' && !pending.value ? data.value?.items : null))

  function setStatus(_status: boolean | null): void {
    status.value = null
    setTimeout(() => {
      status.value = _status
    }, 1000)
  }

  function setSearchData(_data: SearchResponse) {
    data.value = _data
  }

  async function setTerm(_term: string | null): Promise<void> {
    term.value = _term
    await search()
  }

  async function init(): Promise<void> {
    const { routeRaw } = storeToRefs(useRadar())
    const _term = (routeRaw?.value?.query?.search as string) || null
    if (_term) {
      status.value = true
      await setTerm(_term)
    }
  }

  async function search() {
    const { data: result, pending: _pending } = await useFetch(
      () => `/api/search?search=${term?.value?.replaceAll(' ', '+')}&locale=${locale.value}`,
    )
    pending.value = _pending.value
    // @ts-expect-error TODO FIX typerr
    data.value = result.value
  }

  return {
    term,
    status,
    resultItems,
    setStatus,
    setSearchData,
    setTerm,
    init,
    data,
    refresh,
    pending,
  }
})
