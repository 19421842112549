<script lang="ts" setup>
// @ts-expect-error JS library
import { useMq } from 'vue3-mq'
import { useLocaleThemePath } from '~/composables/locale'

const { xs, sm } = useMq()
const props = defineProps({
  isTest: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const agreedPrivacyPolicy: Ref<boolean | null> = ref(false)

function setCookie(name: string, value: string, days: number) {
  if (import.meta.client) {
    let expires = ''
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = `; expires=${date.toUTCString()}`
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`
  }
}

function submitPrivacyPolicy(status: boolean) {
  setCookie('privacy_policy_accepted', `${status}`, status ? 365 : 1)
  agreedPrivacyPolicy.value = status
}

onMounted(() => {
  let status: boolean | null = null
  const cookie: string | undefined = import.meta.client
    ? document.cookie.replace(/(?:(?:^|.*;\s*)privacy_policy_accepted\s*=\s*([^;]*).*$)|^.*$/, '$1')
    : undefined
  if (cookie && (cookie === 'true' || cookie === 'false')) status = !!cookie
  props.isTest ? (agreedPrivacyPolicy.value = status) : setTimeout(() => (agreedPrivacyPolicy.value = status), 10000)
})
</script>

<template lang="pug">
Transition(name="fade-left")
  aside.layout__cookies(
    v-if="agreedPrivacyPolicy === null"
    data-test="cookies-form"
  )
    p {{ $t('privacy-policy.cookies.text-content') }}
      NuxtLink(
        :to="useLocaleThemePath('/privacy-policy')"
        rel="nofollow"
        :title="$t('privacy-policy.cookies.anchor.title')"
        :aria-label="$t('privacy-policy.cookies.anchor.aria-label')"
      ) {{ $t('privacy-policy.cookies.anchor.label') }}
    .layout__cookies__buttons
      button(
        data-test="accept-cookies"
        @click="submitPrivacyPolicy(true)"
      ) {{ $t('privacy-policy.cookies.cta.accept') }}
      button(
        data-test="reject-cookies"
        @click="submitPrivacyPolicy(false)"
      ) {{ $t('privacy-policy.cookies.cta.reject') }}
</template>

<style lang="sass" scoped>
aside.layout__cookies
  @apply fixed bottom-[75px] md:bottom-[22.5vh] xl:bottom-[20vh] left-0 w-fit bg-dark-800 lg:bg-[rgba(0,0,0,0.4)] p-2 border border-primary max-w-[235px] rounded-r-2xl z-50
  p
    @apply text-[11px] text-white
    a
      @apply underline ml-1
  .layout__cookies__buttons
    @apply flex justify-start items-center gap-4 mt-4
    button
      @apply border-2 text-xs font-thin px-2 py-0.5
      &:first-of-type
        @apply bg-primary-o-50 border-primary text-primary
      &:last-of-type
        @apply bg-dark-800 border-white text-white
</style>
