<script lang="ts" setup>
import { MDIIcon } from '~/types/assets'
import { useRoot } from '~/store'

const { toast } = storeToRefs(useRoot())

const { closeToast } = useRoot()
</script>

<template lang="pug">
.toast
  Transition(name="fade-left")
    .toast__outer(v-if="toast")
      .toast__inner
        .toast__inner__text-content(
          v-if="typeof toast === 'string'"
        )
          UnoIcon(:class="MDIIcon.success")
          p {{ toast }}
        Component(:is="toast" v-else)
        span(
          @click="closeToast"
        )
          UnoIcon.text-lg.text-dark-900(:class="MDIIcon.close")
</template>

<style lang="sass" scoped>
.toast__outer
  @apply fixed bottom-[60px] md:bottom-[22.5vh] xl:bottom-[20vh] left-0 w-fit bg-transparent-dark md:bg-primary-o-50 text-dark-900 border md:border-4 border-primary max-w-[325px] text-primary z-50
  .toast__inner
    @apply w-full h-full p-2 md:p-4 relative py-3
    span
      @apply absolute top-0 right-0 cursor-pointer
    .toast__inner__text-content
      @apply flex-center
</style>
