<script lang="ts" setup>
// @ts-expect-error JS library
import { useMq } from 'vue3-mq'
import type { ComputedRef, Ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoot } from '~/store'
import { useRadar } from '~/store/radar'
import { useSearch } from '~/store/search'
import marqueeList, { duration as marqueeDuration, length as marqueeLength } from '~/config/marquee'
import { LordIconSource, LordIconTrigger, MDIIcon } from '~/types/assets'
import type { Flag, LordIcon } from '~/types/assets'
import { ScrollDirection } from '~/types/custom'
import { useLocaleThemePath } from '~/composables/locale'
import type { Theme } from '~/types/theme'
import { localeFlags as locales } from '~/config/locales'
import IconTheme from '~/components/icon/Theme.vue'
import IconFlag from '~/components/icon/Flag.vue'

const root = useRoot()
const radar = useRadar()
const search = useSearch()

const { toggleTailwindMode, reboot: _reboot } = root

const { scrollDirection, scrollPosition, modal, theme: _theme } = storeToRefs(root)

const { status } = storeToRefs(search)

const { routeRaw } = storeToRefs(radar)

const { locale: _locale } = useI18n()

const { openModal, closeModal, themes } = root

const { xs, sm } = useMq()

const menuStatus: Ref<boolean | null> = ref(false)
const locale = ref(_locale.value)
const theme = ref(_theme.value)

const menuStatusModel = computed({
  get: (): boolean | null => menuStatus.value,
  set(status: boolean | null): void {
    menuStatus.value = null
    setTimeout(() => {
      menuStatus.value = status
    }, 700)
  }
})

const searchStatusModel = computed({
  get: (): boolean | null => status.value,
  set(_status: boolean | null): void {
    search.setStatus(_status)
  }
})

const hasMarquee: ComputedRef<boolean> = computed(
  (): boolean =>
    menuStatusModel.value === false &&
    searchStatusModel.value === false &&
    !!marqueeList &&
    Array.isArray(marqueeList) &&
    marqueeLength > 0 &&
    (scrollPosition.value === 0 || scrollDirection.value === ScrollDirection.top)
)

const globe: LordIcon = {
  src: LordIconSource.globe,
  trigger: LordIconTrigger.loop,
  revert: false,
  delay: 4000,
  size: 45
}

const hamburger: LordIcon = {
  src: LordIconSource.hamburger,
  trigger: LordIconTrigger.boomerang,
  revert: true,
  delay: 0,
  size: 45
}

const loader: LordIcon = {
  src: LordIconSource.loaderSpyral,
  trigger: LordIconTrigger.loop,
  revert: true,
  delay: 0,
  size: 45
}

const lightSwitchIcon: LordIcon = {
  src: LordIconSource?.lightSwitch,
  trigger: LordIconTrigger?.hover,
  revert: true,
  delay: 500,
  size: 45
}

const searchIcon: LordIcon = {
  src: LordIconSource?.search,
  trigger: LordIconTrigger?.click,
  revert: true,
  delay: 500,
  size: 45
}

const analyticsIcon: LordIcon = {
  src: LordIconSource?.analytics,
  trigger: LordIconTrigger?.loop,
  revert: true,
  delay: 500,
  size: 52,
  loading: 'lazy'
}

function openRadar() {
  menuStatusModel.value = false
  openModal('LazyModalRadar')
}

function setTheme(_theme: Theme) {
  theme.value = _theme
}

function setLocale(_locale: Flag) {
  if (_locale.countryCode) locale.value = _locale.countryCode
}

function analyzePage() {
  menuStatusModel.value = false
  openModal('LazyModalReport')
}

function shutDown() {
  menuStatusModel.value = false
  root.setTheme(null)
  openModal('LazyModalOffline')
}

function reboot() {
  _reboot(locale.value || undefined, routeRaw.value?.path || undefined, theme.value?.code || undefined)
  closeModal()
  menuStatusModel.value = false
}

onMounted(async () => await search.init())
</script>

<template lang="pug">
header
  .header__main
    .header__main__radar-toggler
      TransitionGroup(
        name="fade-left"
        tag="div"
        :duration="250"
      )
        span(
          v-if="!modal"
          data-test="open-radar"
          @click="openRadar"
        )
          IconLord(v-bind="globe")
        span(
          v-else
          @click="closeModal"
        )
          UnoIcon.text-primary.text-3xl(:class="MDIIcon?.close")
    .header__main__search-toggler(
      data-test="open-search"
      @click="() => searchStatusModel === false ? searchStatusModel = true : () => {}"
    )
      span(
        v-if="!searchStatusModel"
      )
        IconLord(v-bind="searchIcon")
      span(
        v-else
      )
        UnoIcon.text-primary.text-3xl(:class="MDIIcon?.close")
    NuxtLink(
      :to="useLocaleThemePath('/')",
      :title="$t('layout.anchors.home.title')",
      :aria-label="$t('layout.anchors.home.aria-label')"
    )
      IconLogo(
        :size="60"
        :line-drawing="true"
      )
    .header__main__theme-toggler(
      data-test="theme-toggler"
      @click="toggleTailwindMode"
    )
      IconLord(v-bind="lightSwitchIcon")
    .header__main__menu-toggler
      TransitionGroup(
        name="fade-right"
        tag="div"
        :duration="250"
      )
        span(
          v-if="menuStatusModel === false"
          data-test="open-hamburger"
          @click="menuStatusModel = true"
        )
          IconLord(v-bind="hamburger")
        span(
          v-else-if="menuStatusModel === true"
          @click="menuStatusModel = false"
        )
          UnoIcon.text-primary.text-3xl(:class="MDIIcon?.close")
  Transition(name="fade-top")
    .header__main__search__content(
      v-if="searchStatusModel"
      v-click-outside="() => xs || sm ? searchStatusModel = false : () => {}"
      data-test="search-topbar"
    )
      LayoutSearch
  Transition(name="fade-top")
    nav.header__main__menu(
      v-if="menuStatusModel"
      v-click-outside="() => menuStatusModel = false"
      data-test="hamburger-menu"
    )
      .header__main__menu__themes
        UIWheelPicker(
          :options="[].concat(...Array(3).fill(themes))"
          :component="IconTheme"
          :slide-props="{ size: 'lg' }"
          @on-select="setTheme"
        )
      .header__main__menu__locales
        UIWheelPicker(
          :options="[].concat(...Array(3).fill(locales))"
          :component="IconFlag"
          :slide-props="{ size: 'lg' }"
          @on-select="setLocale"
        )
      .header__main__menu__buttons
        span(data-test="lighthouse-metrics", @click="analyzePage")
          IconLord(v-bind="analyticsIcon")
        UIButton(
          class-name="bg-dark-900 text-secondary border-y border-primary"
          @on-click="reboot"
        ) REBOOT
        span(data-test="power-off", @click="shutDown")
          UnoIcon.text-primary.text-4xl(:class="MDIIcon?.power")
  Transition(name="fade-top")
    .header__marquee(v-if='hasMarquee')
      Vue3Marquee(
        direction='right'
        :duration='marqueeDuration'
        :pause-on-click='true'
        :pause-on-hover='true'
      )
        span.header__marquee__item(
          v-for='(marqueeItem, index) in marqueeList'
          :key='index'
          :class='{ word: true, odd: index % 2 === 0, even: index % 2 === 1 }'
        ) {{ marqueeItem }}
</template>

<style lang="sass" scoped>
header
  @apply w-full lg:hidden absolute top-0 left-0 right-0 z-50
  .header__main
    @apply w-full flex justify-between items-center bg-light-700 dark:bg-dynamic-dark px-2 py-1 border-4 border-primary relative z-40
    .header__main__radar-toggler,
    .header__main__menu-toggler
      @apply cursor-pointer min-w-[50px] h-[50px] flex-center overflow-hidden
  .header__marquee
    @apply w-full py-0.5 bg-primary-o-75 border-4 border-t-0 border-primary
    .header__marquee__item
      @apply text-dark-900 mx-1 font-bold text-[11px]
  .header__main__search__content
    @apply w-full border-4 border-t-0 border-primary p-3 bg-black
  nav.header__main__menu
    @apply w-full p-1 bg-transparent-dark border-4 border-t-0 border-primary pb-4
    .header__main__menu__themes,
    .header__main__menu__locales
      @apply bg-dark-900 rounded-md mt-2
    .header__main__menu__buttons
      @apply w-full flex justify-between items-center mt-4 px-3
</style>
