<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useRoot } from '~/store'
import { useThemeOptions } from '~/composables/theme'
import { useLocaleOptions } from '~/composables/locale'
import { LordIconSource, LordIconTrigger, MDIIcon } from '~/types/assets'
import type { ListItem } from '~/types/custom'
import { useSearch } from '~/store/search'
import type { LordIcon } from '~/types/assets'

const { toggleTailwindMode } = useRoot()

const search = useSearch()

const { themeOption, themeOptions, onThemeSwitch } = useThemeOptions()

const { localeOption, localeOptions, onLocaleSwitch } = useLocaleOptions()

const { status } = storeToRefs(search)

const searchIcon: LordIcon = {
  src: LordIconSource?.search,
  trigger: LordIconTrigger?.click,
  revert: true,
  delay: 500,
  size: 50
}

const lightSwitchIcon: LordIcon = {
  src: LordIconSource?.lightSwitch,
  trigger: LordIconTrigger?.hover,
  revert: true,
  delay: 500,
  size: 50
}

onMounted(async () => await search.init())
</script>

<template lang="pug">
.control-panel
  UIDropdown(
    :options="localeOptions"
    :option="localeOption"
    list-type="locale"
    class="control-panel__locale"
    @update:option="(item: ListItem) => onLocaleSwitch(item?.title || item?.textContent || 'unknown')"
  )
  UIDropdown(
    :options="themeOptions"
    :option="themeOption"
    list-type="theme"
    class="control-panel__theme"
    @update:option="(item: ListItem) => onThemeSwitch(item?.title || item?.textContent || 'unknown')"
  )
  .layout-theme-toggler(
    v-tooltip="'Switch between light & dark theme'"
    data-test="theme-toggler"
    @click="toggleTailwindMode"
  )
    IconLord(v-bind="lightSwitchIcon")
  .control-panel__search(
    v-tooltip="`Search around Lorenzo Rottigni's portfolio`"
    data-test="search-toggler"
    @click="() => search.setStatus(!status)"
  )
    Transition(name="fade-right")
      span(v-if="status === false")
        IconLord(v-bind="searchIcon")
      span(v-else-if="status === true")
        UnoIcon.text-3xl.text-primary(:class="MDIIcon?.close")
</template>

<style lang="sass" scoped>
.control-panel
  @apply flex items-center gap-4 p-4 bg-light-300 dark:bg-black border-l-4 border-b-4 dark:border border-primary relative
  .control-panel__search
    @apply cursor-pointer flex-center
  .layout-theme-toggler
    @apply flex-shrink-0 hidden xl:flex items-center justify-center transition-all cursor-pointer
</style>
