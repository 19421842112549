const config: string[] = [
  'Hello Wêreld!',
  'Përshendetje Botë!',
  'ሰላም ልዑል!',
  'مرحبا بالعالم!',
  'Բարեւ աշխարհ!',
  'Kaixo Mundua!',
  'Прывітанне Сусвет!',
  'ওহে বিশ্ব!',
  'Здравей свят!',
  'Hola món!',
  'Moni Dziko Lapansi!',
  '你好世界！',
  'Pozdrav svijete!',
  'Ahoj světe!',
  'Hej Verden!',
  'Hallo Wereld!',
  'Hello World!',
  'Tere maailm!',
  'Hei maailma!',
  'Bonjour monde!',
  'Hallo wrâld!',
  'გამარჯობა მსოფლიო!',
  'Hallo Welt!',
  'Γειά σου Κόσμε!',
  'Sannu Duniya!',
  'שלום עולם!',
  'नमस्ते दुनिया!',
  'Helló Világ!',
  'Halló heimur!',
  'Ndewo Ụwa!',
  'Halo Dunia!',
  'Ciao mondo!',
  'こんにちは世界！',
  'Сәлем Әлем!',
  'សួស្តី​ពិភពលោក!',
  'Салам дүйнө!',
  'ສະ​ບາຍ​ດີ​ຊາວ​ໂລກ!',
  'Sveika pasaule!',
  'Labas pasauli!',
  'Moien Welt!',
  'Здраво свету!',
  'ഹലോ വേൾഡ്!',
  'Сайн уу дэлхий!',
  'မင်္ဂလာပါကမ္ဘာလောက!',
  'नमस्कार संसार!',
  'Здраво Свете!',
  'හෙලෝ වර්ල්ඩ්!',
  '¡Hola Mundo!',
  'העלא וועלט!',
]

export const length: number = config.length

export const duration: number = length * 1.5

export default config
