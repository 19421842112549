<script lang="ts" setup>
import { LordIconSource, LordIconTrigger, MDIIcon } from '~/types/assets'
import type { LordIcon } from '~/types/assets'
import { useRoot } from '~/store'

const { toggleTailwindMode } = useRoot()

const iconLove: LordIcon = {
  src: LordIconSource.heart,
  trigger: LordIconTrigger.loop,
  revert: false,
  delay: 4000,
  size: 25,
  minimal: false,
}

const downIcon: LordIcon = {
  src: LordIconSource?.down,
  trigger: LordIconTrigger?.loop,
  revert: true,
  delay: 500,
  size: 30,
}
</script>

<template lang="pug">
footer
  h6
    | Coded with
    IconLord(v-bind="iconLove")
    | by Lorenzo Rottigni
</template>

<style lang="sass" scoped>
footer
  @apply block lg:hidden flex justify-around border-4 border-primary fixed bottom-0 left-0 right-0 px-2 py-1 bg-light-700 dark:bg-black text-primary z-40
  h6
    @apply text-center text-base flex items-center text-sm
  .theme-toggler
    @apply border border-primary rounded-[50%] p-2 flex-shrink-0 flex items-center justify-center border-dashed hover:rotate-180 hover:bg-dark-800 transition-all cursor-pointer absolute -top-4 right-3 translate-y-[-100%]
</style>
